import { GetServerSideProps } from 'next';
import {
  CMSPageIds,
  CMSPageResponse,
  Cookies,
  DealTypeForTab,
  GearResponse,
  HomePageData,
  MenuResponse,
} from 'types';
import { getCustomerActivities, getGearList, getMenuData, getPageData } from 'api';
import { FEED_TABS } from 'consts';
import { getCommonOgData } from 'utils/seoFormatters';
import { formatQueryFilters, getCanonicalUrlFromContext } from 'utils/formatters';
import { Home, HomeProps } from 'containers/HomePage';
import { LIST_LIMIT } from '../containers/Gear';

const HomePage = (props: HomeProps) => {
  return <Home {...props} />;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query } = context;
  const cookies: Cookies = {
    parsed: context.req.cookies as Record<string, string>,
  };
  const appliedFilters = formatQueryFilters(query as Record<string, string | string[]>);
  appliedFilters.type = appliedFilters.type || 'sell'
  const pageId: CMSPageIds = 'homepage';
  const force = !!query.force;
  const tab =
    context.query.tab && FEED_TABS.includes(String(context.query.tab) as DealTypeForTab) ? context.query.tab : 'sell';
  const fromTime = Date.now() / 1000;

  const activitiesRequest = getCustomerActivities({}, force);
  const menuDataRequest: Promise<MenuResponse> = getMenuData(force);
  const pageDataRequest: Promise<CMSPageResponse<HomePageData>> = getPageData(pageId, force);
  const gearsRequest: Promise<GearResponse> = getGearList({
    ...appliedFilters,
    limit: LIST_LIMIT * (query.page ? parseInt(query.page) : 1),
    page: 1,
  });

  const [activities, menuData, pageData, gears] = await Promise.all([
    activitiesRequest,
    menuDataRequest,
    pageDataRequest,
    gearsRequest,
  ]);

  return {
    props: {
      activities,
      appliedFilters,
      initialGear: gears,
      fromTime,
      menuData,
      pageData: pageData.content ? pageData.content : {},
      ogData: getCommonOgData({
        url: getCanonicalUrlFromContext(context),
      }),
      tab,
    },
  };
};

export default HomePage;
